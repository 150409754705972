<template>
  <div
    v-if="isVisible"
    class="cookies-banner"
  >
    <div class="mr-3">
      <h4>
        <font-awesome-icon :icon="['fa', 'cookie-bite']" class="mr-50" />
        {{ $t('legal.banner-cookies.title') }}
      </h4>
      <p class="mb-50">
        {{ $t('legal.banner-cookies.text') }} {{ $t('legal.banner-cookies.details') }}
        <a :href="termsConditionsURL" target="_blank">{{ $t('legal.banner-cookies.link') }}</a>
      </p>
    </div>
    <div class="align-self-center font-weight-bold text-right">
      <b-button variant="primary" class="btn" @click="acceptCookies">
        {{ $t('legal.banner-cookies.button') }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerCookies',
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    colorPrimary() {
      return this.currentCollective.themeCostumization?.primaryColor || '#5dbfc9';
    },
    colorSecondary() {
      return this.currentCollective.themeCostumization?.secondaryColor || '#a5a5a5';
    },
    termsConditionsURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          this.$store.getters.mainCollective?.subdomain
            || this.$store.getters.currentCollective?.subdomain
            || 'app',
        ).replace(
          '{slug}',
          this.$store.getters.mainCollective?.slug
            || this.$store.getters.currentCollective?.slug || 'my',
        )}/cookies_policy?locale=${this.currentLocale}`
      );
    },
  },

  created() {
    const acceptedCookies = this.$cookies.get('cookiesAccepted');
    if (!acceptedCookies) {
      this.isVisible = true;
    }
  },

  methods: {
    acceptCookies() {
      this.$cookies.set('cookiesAccepted', true, '1y');
      this.isVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cookies-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999999 !important;
  padding: 25px 95px !important;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100vw;
  box-shadow: 0 0 20px 3px rgb(31 32 48 / 15%);
  .btn {
    border-radius: 0;
    padding: 20px;
    min-width:156px;
  }
}

@media (max-width: 992px) {
  .cookies-banner {
    flex-wrap: wrap;
    padding: 25px 30px !important;
  }
}
</style>
